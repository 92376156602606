import { ref, computed, onMounted } from 'vue'
import store from '@/store';
import router from '@/routes/routes.js';
import Collection from '@/store/services/traits/collection'

export default function useBrainstorm() {
    const route = router.currentRoute;
    const collection = new Collection;
    const selectedModuleId = ref(null)
    const properties = ref([])
    const selectedPropertiesId = ref(null)
    // const propertyCollections = ref([])

    // const visualizationData = computed(() => {
    //     return store.state.programmatic.visualizationData
    // })

    const visualizationData = ref([])
    
    const scenarios = computed(() => {
        return store.getters['programmatic/scenarios']
    })

    const selectedScenario = computed(() => {
        if(scenarios.value.length) {
            return scenarios.value.filter(scenario => scenario.id == route.params.scenarioId)
        }
        return []
    })

    const selectedModule = computed(() => {
        if(visualizationData.value.length && selectedModuleId.value) {
            return visualizationData.value.find(item => item.id == selectedModuleId.value)
        }
        return {}
    })

    const fetchVisualizationData = async (projectId) => {
        const {data: {data}} = await store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: route.params.brainstormId})
        if(data) {
            // const properData = _getProperData(data)
            // store.commit('programmatic/setVisualizationData', data.modules)
            console.log(data, 'data first')
            visualizationData.value = data.modules
            _setInitialData(data.modules)
        }
    }

    const getSidebarData = (data) => {
        if(data?.length) {
            return data.map(module => ({
                id: module.id,
                color: collection.getModuleBg2(module,1, store.getters['global/colorCodes'])
            }))
        }
    }

    const setModuleId = (id) => {
        selectedModuleId.value = id
    }

    const _setInitialData = (data) => {
        const firstModule = data[0]
        if(firstModule) {
            setModuleId(firstModule.id)
            _fetchProperties(firstModule.id)
        }
    }

    // const _getProperData = (data) => {
    //     return JSON.parse(JSON.stringify(data).replace(/scenario_collections/g, 'collections'))
    // }

    const _fetchProperties = async (moduleId) => {
        try {
            const {data: {data}} = await store.dispatch("manageProperty/getPropertiesToModule", moduleId)
            properties.value = data
            _setInitialPropertiesData(data)
        }
        catch(err) {
            //err
        }
        
    }

    const _setInitialPropertiesData = (data) => {
        if(data?.length) {
            selectedPropertiesId.value = data[0].id
        }
    }

    const getParameter = (propertiesId, parameterId) => {
        let propertyCollection = getPropertyCollection(propertiesId)
        return propertyCollection.length ? propertyCollection.find(item => item.id == parameterId) : {}
    }

    const getPropertyCollection = (propertiesId) => {
        if(properties.value?.length) {
            const { property_collections } = properties.value.find(property => property.id == propertiesId)
            return property_collections ? property_collections : []
        }
        return []
    }

    onMounted(() => {
        const projectId = route.params.projectId
        fetchVisualizationData()
        store.dispatch('programmatic/loadScenarios', projectId)

    })

    return {
        visualizationData,
        getSidebarData,
        selectedModuleId,
        setModuleId,
        selectedModule,
        scenarios,
        properties,
        selectedPropertiesId,
        getPropertyCollection,
        selectedScenario,
        getParameter
    }
}