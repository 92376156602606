<template>
    <div class="flex gap-12">
        <div class="flex items-center text-primary-one">
            <span class="fw-600 fs-14">Active Scenario: </span>
            <p class="fs-400 fw-400 ml-1">{{ scenarioTitle }}</p>
        </div>
        <div class="flex items-center text-primary-one">
            <span class="fw-600 fs-14">Data Source:</span>
            <select v-model="dataSource" class="bg-transparent focus:outline-primary-six">
                <option class="fs-400 fw-400" :value="null">Scenario Properties</option>
            </select>
        </div>
        <div class="flex items-center text-primary-one">
            <span class="fw-600 fs-14">Property Set:</span>
            <select v-model="selectedProperty" class="bg-transparent focus:outline-primary-six">
                <option
                    class="fs-400 fw-400"
                    v-for="(item, index) in properties" 
                    :key="index"
                    :value="item.id"
                >
                    {{ item.title }}
                </option>
            </select>
        </div>
        <!-- <div class="flex items-center text-primary-one">
            <span class="fw-600 fs-14">Property Set:</span>
            <select v-model="selectedParameter" class="bg-transparent focus:outline-primary-six">
                <option
                    class="fs-400 fw-400"
                    v-for="(item, index) in getPropertyCollection(selectedPropertiesId)" 
                    :key="index"
                    :value="item.id"
                >
                    {{ item.title }}
                </option>
            </select>
        </div> -->
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import router from '@/routes/routes.js';
const route = router.currentRoute;
const emit = defineEmits(['onSelectProperty'])
const props = defineProps({
    scenarioTitle: String,
    properties: Array,
    selectedPropertiesId: [Number, String],
    getPropertyCollection: Function,
    parameterId: [Number, String],
})
const dataSource = ref(null)

const selectedProperty = computed({
    get() {
        return props.selectedPropertiesId
    },
    set(val) {
        emit('onSelectProperty', val)
    }
})

</script>