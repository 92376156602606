<template>
    <div>
        <div class="flex">
            <!-- <div
                v-for="(property, pIndex) in properties"
                :key="pIndex" 
                class="logic-cell-width text-center truncate px-1 text-secondary-two fs-14 fw-400 mb-1">
                {{ getParameter(selectedPropertiesId, property.id).title }}
            </div> -->
            <template v-for="(param, paramIndex) in properties">
                <WidgetHeader
                    :key="paramIndex"
                    :text="param.title"
                    :parameterId="paramIndex"
                    :minimizedParamIds="minimizedParamIds"
                    @toggleWidget="handleClick(paramIndex)"
                    @onChecked="setSharedParameter({parameter: param, widget})"
                    :isChecked="activeParameterId == param.id"
                    :showSelect="false"
                />
            </template>
        </div>
        
        <div :key="index" v-for="(item, index) in filteredCollections">
            <div class="flex">
                <div  
                    :key="pIndex" v-for="(property, pIndex) in properties"
                    :style="{
                        height: item.expandedHeight ? item.expandedHeight+'px' : '30px',
                        width: minimizedParamIds.includes(pIndex) ? '88px' : '303px' 
                    }" 
                    style="background: #4e768c;" 
                    class="logic-cell-width h-10 text-center widget-parameter flex items-center justify-center fs-16">
                    {{ getParameterValue(item, {id: property.id}) }}
                </div>
            </div>
            <!-- :text="getParameterTextDefinition(getParameterValue(item, parameter), widget.definition_style)" -->
            <!-- :bg="getParameterBg(getParameterValue(item, parameter), widget.definition_style)" -->
            <!-- <WidgetParameter
                :key="index"
                bg="#4e768c"
                :value="getParameterValue(item, {id: parameterId})"
                :expandedHeight="item.expandedHeight"
                :minimizedParamIds="[parameterId]"
                :parameterId="parameterId"
                :isExpanded="false"
            /> -->
        </div >
    </div>
</template>

<script setup>
import { getParameterValue } from '@/store/modules/roadmap/services'
import WidgetHeader from '@/components/roadmap/visualization/WidgetHeader'
import useWidgetHeader from '@/components/roadmap/visualization/useWidgetHeader'
import useSystemContent from '@/components/shared/visualization/systemContent/useSystemContent'

const { setSharedParameter, activeParameterId } = useSystemContent();
const { minimizedParamIds, handleClick } = useWidgetHeader()

const props = defineProps({
    selectedPropertiesId: [String, Number],
    properties: Array,
    filteredCollections: Array,
    getParameter: Function,
})
</script>