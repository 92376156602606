<template>
    <WrapperVisualization :sidebarData="getSidebarData(visualizationData)" :selectedModuleId="selectedModuleId"
        :setModuleId="setModuleId" @onChangeLevelClearance="value => levelClearance = value">
        <template #header>
            <VisualizationHeader :scenarioTitle="scenarioTitle" :properties="properties"
                :selectedPropertiesId="selectedPropertiesId" :getPropertyCollection="getPropertyCollection"
                :parameterId="parameterId" @onSelectProperty="id => selectedPropertiesId = id" />
        </template>
        <div v-if="selectedModuleId" class="flex w-full overflow-x-auto">
            <SystemContent :module="selectedModule" :levelClearance="levelClearance" class="mt-20" />
            <PropertyVisualization class="mt-14" :filteredCollections="getFilteredCollections(selectedModule)"
                :properties="getPropertyCollection(selectedPropertiesId)" :getParameter="getParameter"
                :selectedPropertiesId="selectedPropertiesId" />

        </div>
    </WrapperVisualization>
</template>

<script setup>
    import { ref, computed, onMounted } from 'vue';
    import axios from 'axios'
    import router from '@/routes/routes.js';
    import SystemContent from '@/components/shared/visualization/systemContent/Index'
    import useWidgetVisualization from '@/composable/useWidgetVisualization'
    import PropertyVisualization from '@/components/roadmap/visualization/PropertyVisualization'
    import WrapperVisualization from '@/elements/layout/WrapperVisualization'
    import VisualizationHeader from './VisualizationHeader'
    import useBrainstorm from '@/store/modules/brainstorm/useBrainstorm'
    import WidgetParameter from '@/components/roadmap/visualization/WidgetParameter'

    import { getParameterTitles, getParameterTextDefinition, getParameterValue, getParameterBg} from '@/store/modules/roadmap/services.js'

    const route = router.currentRoute;
    const project = ref(null)
    const scenarioTitle = computed(() => project.value ? project.value.scenario.title : '')

    const { getFilteredCollections } = useWidgetVisualization()

    const parameterId = ref(null)

    const { 
        visualizationData,
        getSidebarData,
        selectedModuleId,
        setModuleId,
        selectedModule,
        scenarios,
        properties,
        selectedPropertiesId,
        getPropertyCollection,
        getParameter
    } = useBrainstorm()

    const levelClearance = ref(4);

    onMounted(async () => {
        const data = await axios.get(`projects/${route.params.projectId}?solid_object=true`).then(({data})=>data.data)
        project.value = data
    })
</script>

<style>
.logic-cell-width {
    width: 230px
}

.widget-parameter {
    /* width: 303px; */
    /* min-height: 30px; */
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    border-top: 0.5px solid #fff;
    border-bottom: 0.5px solid #fff;
    /* padding-left: 18px; */
    color: #fff;
}
</style>